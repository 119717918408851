import { RemixBrowser , useLocation, useMatches } from '@remix-run/react';
import { StrictMode, startTransition , useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import * as Sentry from '@sentry/remix';

Sentry.init( {
  dsn: window.env?.SENTRY_IO_DSN,
  integrations: [
    Sentry.browserTracingIntegration( {
      useEffect,
      useLocation,
      useMatches
    } )
  ],
  enabled: window.env?.NODE_ENV !== 'development',
  environment: window.env?.NODE_ENV,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', 'https://evotree.io', 'https://evotree-web.vercel.app'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
} );

const hydrate = () => {
  startTransition( () => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>
    );
  } );
}

if ( typeof requestIdleCallback === 'function' ) {
  requestIdleCallback( hydrate );
} else {
  setTimeout( hydrate, 1 );
}
